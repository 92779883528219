
import { reactive } from "vue"
import TabsComponent from "../navigation/TabsComponent.vue"
import CompaniesService from "@/services/CompaniesService"

export default {
  name: "Team",
  components: { TabsComponent },
  setup() {
    const companyService = new CompaniesService()
    const companies = reactive([])
    const tabItems = reactive([
      {
        label: "Employees",
        routeName: "employees",
        attributes: {},
      },
      {
        label: "Companies",
        routeName: "companies",
        attributes: {
          permissions:
            '["SUPER_SUPPORT", "SUPER_SALES", "ADMIN", "DEV", "SUPPORT", "SALES", "FINANCE"]',
        },
      },
      {
        label: "User Groups",
        routeName: "usergroups",
        attributes: {},
      },
    ])

    companyService.getCompanies().then((result: any) => {
      if (result.data && result.data.length) {
        result.data.forEach((company: any) => {
          companies.push(company)
        })
      }
    })

    return {
      tabItems,
      companies,
    }
  },
}
